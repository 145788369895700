import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import Safety from "../components/Solutions/Safety/Safety";

import Favicon from "../images/Favicon.png";

const SafetyPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Safety - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="keywords" content="Car Toppers, GPS, franchises"/>
                <meta name="description"
                      content="Enhance last mile delivery driver safety with DrivosityCORE. Our car topper solutions track unsafe driving behaviors, incentivizing safer driving practices and leading to a reduction in insurance claims. Request a demo today!"/>
            </Helmet>
            <Layout component={<Safety/>}/>
        </>
    )
}

export default SafetyPage;
